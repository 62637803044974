import React, { useRef, useState, useLayoutEffect, useEffect } from "react";
import { Heading } from "../../style/Heading";
import { Box, Container, Flex, Wrapper } from "../../style/Grid";
import { Image } from "../../style/Image";
import { MapBoxBranch, MapBoxImage } from "../../style/BranchMap";
import { Text } from "../../style/Text";
import nbsp from "../../utils/nbsp";

const BranchMap = ({ branches }) => {
  const [activeBranch, setActiveBranch] = useState(0);
  const mapImageContainerEl = useRef(null);

  const [mapImageContainerHeight, setMapImageContainerHeight] = useState(0);

  const updateHeight = () => {
    const offsetWidth = mapImageContainerEl?.current?.offsetWidth || 0;
    setMapImageContainerHeight(
      parseFloat(offsetWidth) * parseFloat(branches[activeBranch]?.ratio)
    );
  };

  useEffect(() => {
    updateHeight();
  });

  useLayoutEffect(() => {
    window.addEventListener("resize", updateHeight);
    updateHeight();
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  return (
    <Wrapper as="section">
      <Container flexDirection="column" mt={[5, null, null, 6]}>
        <Heading
          as={"h2"}
          fontSize={[4, null, 6]}
          fontWeight="light"
          mb={[4, null, null, 5]}
        >
          {nbsp("Kde sedíme a co je poblíž?")}
        </Heading>

        <Flex flexDirection={["column-reverse", null, null, "row"]}>
          <Box
            ref={mapImageContainerEl}
            position="relative"
            height={[`${mapImageContainerHeight}px`, null, null, null]}
            paddingBottom={[
              "calc(100% + 62px)",
              null,
              null,
              "calc(100% - 250px)",
            ]}
            width={["calc(100% + 62px)", null, "100%", "100%", "100%"]}
            margin={["0 -31px", null, 0, 0, 0]}
          >
            {branches?.map((branch, index) => (
              <MapBoxImage
                active={activeBranch === index}
                key={index}
                backgroundImage={`url(${branch.map})`}
                alt=""
              >
                {branch?.map?.childImageSharp && (
                  <Image image={branch.map} cover />
                )}
              </MapBoxImage>
            ))}
          </Box>

          <Flex
            minWidth={["100%", null, null, "250px"]}
            flexDirection={["row", null, null, "column"]}
            position="relative"
          >
            {branches?.map((branch, index) => {
              return (
                <MapBoxBranch active={activeBranch === index}>
                  <button onClick={() => setActiveBranch(index)}>
                    <Heading
                      as="h3"
                      fontSize={[
                        activeBranch === index ? 3 : 2,
                        null,
                        null,
                        activeBranch === index ? 4 : 2,
                      ]}
                      fontWeight={activeBranch === index ? "bold" : "regular"}
                    >
                      {branch.city}
                    </Heading>
                  </button>

                  <div>
                    <Text
                      fontSize={2}
                      lineHeight={4}
                      dangerouslySetInnerHTML={{ __html: branch.address }}
                    />
                    <Text fontSize={2} lineHeight={4} mb={6}>
                      {branch.phone}
                    </Text>
                  </div>
                </MapBoxBranch>
              );
            })}
          </Flex>
        </Flex>
      </Container>
    </Wrapper>
  );
};

export default BranchMap;
