import styled from "styled-components";
import { backgroundImage } from "styled-system";
import { themeGet } from "@styled-system/theme-get";

const MapBoxImage = styled.div`
  opacity: ${props => (props.active ? 1 : 0)};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  transition: opacity 600ms;
  background-size: contain;
  ${backgroundImage};
  background-repeat: no-repeat;
`;

const MapBoxBranch = styled.div`
  padding-left: 60px;
  & > button {
    position: relative;
    appearance: none;
    background: none;
    border: 0;
    padding-bottom: 10px;
    margin-bottom: ${themeGet("space.5")};
    cursor: pointer;
    &:hover {
      &:after {
        background: ${themeGet("colors.black.100")};
      }
    }
    &:focus {
      outline: none;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: ${props => (props.active ? "-130px" : "0px")};
      height: 2px;
      width: ${props => (props.active ? "160px" : "30px")};
      background: ${props =>
        props.active
          ? themeGet("colors.black.100")
          : themeGet("colors.black.40")};
      transition: width 600ms ease-in-out, left 600ms ease-in-out,
        background 600ms ease-in-out;
    }
  }

  & > div {
    overflow: hidden;
    opacity: ${props => (!props.active ? 0 : 1)};
    max-height: ${props => (props.active ? "250px" : "0px")};
    height: auto;
    transition: max-height 600ms, opacity 600ms;
  }

  @media screen and (max-width: ${themeGet("breakpoints.lg")}) {
    padding: 0 0 200px 0;
    display: flex;
    &:first-of-type {
      button {
        margin-left: -10px;
        text-align: left;
      }
    }
    &:last-of-type {
      button {
        text-align: right;
        &:before {
          display: none;
        }
      }
    }
    & > button {
      min-width: 100px;
      margin-bottom: 0;
      padding: 10px;
      &:after {
        display: none;
      }
      &:before {
        content: "";
        position: absolute;
        right: 0;
        top: 12px;
        bottom: 12px;
        width: 1px;
        background-color: ${themeGet("colors.black.40")};
      }
    }
    & > div {
      position: absolute;
      top: 60px;
      left: 0;
      transition: none;
    }
  }
`;

export { MapBoxImage, MapBoxBranch };
