import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Bubble from "../components/Bubble";
import { Heading } from "../style/Heading";
import Header from "../components/Header";
import ReferenceCarousel from "../components/ReferenceCarousel";
import CareerGrowth from "../components/CareerGrowth";
import TriangleBlur from "../style/Header/triangleBlur";
import { URL } from "../constants";
import TopicLinks from "../components/TopicLinks";
import { Container, Wrapper } from "../style/Grid";
import { Text } from "../style/Text";
import BranchMap from "../components/BranchMap";
import usePageView from "../utils/usePageView";
import SEO from "../components/seo";

import cocaCola from "../images/references/coca-cola.svg";
import skoda from "../images/references/skoda.svg";
import nbsp from "../utils/nbsp";

// const AboutBubbles = withBubbleList(Bubble)

const AboutPage = ({ data, location }) => {
  const swiperContentData = [
    {
      logo: data.avastLogo,
      isLogoSVG: false,
      name: "Avast",
      title:
        "Jejich bezpečnostní řešení krotí počítačové hackery a chrání vaše data. Nejen proto jsme hrdými auditory skupiny Avast.",
      cta: {
        to: "https://avast.cz/",
        title: "Chci vědět víc",
        blank: true,
      },
    },
    {
      logo: data.cashbotLogo,
      isLogoSVG: false,
      name: "Cashbot",
      title:
        "Jsme tvůrci systému, přes který si firmy půjčují peníze na základě vystavených faktur s dlouhou splatností.",
      cta: {
        to: "https://cashbot.cz/",
        title: "Chci vědět víc",
        blank: true,
      },
    },
    {
      logo: cocaCola,
      isLogoSVG: true,
      name: "Coca-Cola",
      title:
        "Poskytli jsme finanční poradenství, které napomohlo k úspěšnému spojení s TOMA Natura.",
      cta: {
        to: "https://www.coca-cola.cz/",
        title: "Chci vědět víc",
        blank: true,
      },
    },
    {
      logo: skoda,
      isLogoSVG: true,
      name: "ŠKODA AUTO",
      title:
        "Podíleli jsme se na rebrandingu prodejních míst tak, aby na zákazníky působila profesionálně, příjemně a byla v souladu s hodnotami značky.",
      cta: {
        to: "https://www.skoda-auto.cz/",
        title: "Chci vědět víc",
        blank: true,
      },
    },
  ];

  const topicLinksData = [
    {
      heading: "Audit",
      link: `${URL.department}/audit`,
      image: data.topicLinksImage1,
    },
    {
      heading: "Forenzní služby",
      link: `${URL.department}/forenzni-sluzby`,
      image: data.topicLinksImage2,
    },
    {
      heading: "Business Consulting",
      link: `${URL.department}/business-consulting`,
      image: data.topicLinksImage3,
    },
    {
      heading: "Technology Consulting",
      link: `${URL.department}/technology-consulting`,
      image: data.topicLinksImage4,
    },
    {
      heading: "Daně",
      link: `${URL.department}/dane`,
      image: data.topicLinksImage5,
    },
    {
      heading: "Strategie a transakce",
      link: `${URL.department}/strategie-a-transakce`,
      image: data.topicLinksImage6,
    },
    {
      heading: "Právo",
      link: `${URL.department}/pravo`,
      image: data.topicLinksImage7,
    },
  ];

  const careerGrowthData = [
    {
      position: "Intern",
      description: "Při škole",
      text: "Chvíli v práci, chvíli ve škole. Přidat se k nám můžeš už jako student. Nebudeš stát stranou, zapojíme tě rovnou do projektů. Zvládat se to dá, nemusíš mít obavy. Získáš smysluplnou praxi  a uděláš si dokonalou představu, o čem je poradenství EY.",
      image: data.careerImage1,
    },
    {
      position: "Junior konzultant",
      description: "Hned po škole",
      text: "Projekty, školení, projekty, školení. Od začátku se budeš hodně učit, plnit dílčí úkoly, konzultovat s týmem a chodit na schůzky s klienty. Budeš hodně pracovat, ale určitě si najdeš čas na koníčky. Poznáš spoustu kolegů, ze kterých se brzy stanou skvělí kamarádi.",
      image: data.careerImage2,
    },
    {
      position: "Senior konzultant",
      description: "Za 2 - 3 roky",
      text: "Dostaneš na starost tým juniorů a přijdou nové, komplikovanější projekty. Začneš ve větším cestovat po Česku i do ciziny a stane se z tebe skutečný odborník, jehož slovo má váhu. Budeš mít větší zodpovědnost jak vůči kolegům, tak klientům.",
      image: data.careerImage3,
    },
    {
      position: "Manager",
      description: "Za 5 - 6 let",
      text: "Poradenství bude tvůj životní styl. Budeš perfektně znát procesy, mít dokonalý přehled o dění v týmu a každý den nahlédneš do firem z různých odvětví. Staneš se tváří našich projektů a postupně začneš rozvíjet kontakty a přinášet do EY byznys.",
      image: data.careerImage4,
    },
    {
      position: "Senior manager",
      description: "Po několika letech jako manažer",
      text: "Budeš mít komplexní práci. Od kontaktů s klienty přes vyhledávání nového byznysu až po zodpovědnost za kvalitu projektů a řízení celého týmu. Budeš předávat své zkušenosti kolegům a spolupodílet se na strategických rozhodnutích firmy.",
      image: data.careerImage5,
    },
    {
      position: "Partner",
      description: "Možná jednou",
      text: "Tvoje role bude víc strategická a obchodní. Dohlédneš na fungování týmu a spokojenost klientů. Budeš hledat dlouhodobé trendy, přednášet o nich, školit kolegy, domlouvat nové projekty a řídit směrování poradenství v Česku i v rámci celého EY.",
      image: data.careerImage6,
    },
  ];

  const branchMapData = [
    {
      city: "Praha",
      address: "EY<br />Na Florenci 2116/15<br />Praha 1, 110 00",
      phone: "+420 225 335 111",
      map: data.brancheMapImage1,
      ratio: "1.516‬",
    },
    {
      city: "Brno",
      address: "EY<br />Orlí 36<br />Brno, 602 00",
      phone: "+420 541 636 424",
      map: data.brancheMapImage2,
      ratio: "1.516‬",
    },
    {
      city: "Ostrava",
      address: "EY<br />28. října 150<br />Ostrava, 702 00",
      phone: "+420 595 636 327",
      map: data.brancheMapImage3,
      ratio: "1.237",
    },
  ];

  usePageView(location.pathname, "O EY", "");
  const { frontmatter: content } = data.mdx;

  return (
    <Layout location={location}>
      <SEO title={content.metaTitle} description={content.metaDescription} />
      <Header
        bgImage={content.headerImage.image}
        minHeight={["450px", null, "751px", null, null]}
        bottomComponents={<TriangleBlur />}
        containerJustifyContent="flex-end"
        shadowAfter={true}
      >
        <Heading
          as="h1"
          color="white.100"
          fontSize={[5, 6, 6, "48px", null]}
          fontWeight="300"
          lineHeight="1.3"
          maxWidth={["320px", "500px", "650px", null, null]}
          mb={["24px", null, null, null, null]}
        >
          {nbsp(content.title)}
        </Heading>
        <Text
          color="white.100"
          fontWeight="300"
          fontSize={[2, null, null, 2, null]}
          lineHeight="24px"
          maxWidth={["280px", "300px", "350px", "425px", null]}
          mb={["45px", null, "84px", 6, null]}
        >
          {nbsp(content.headerImage.imageAlt)}
        </Text>
      </Header>

      <ReferenceCarousel swiperContent={swiperContentData} />

      <TopicLinks as="section" items={topicLinksData}>
        Co u nás můžeš dělat?
      </TopicLinks>

      {content.whoworkwithey && (
        <Wrapper as="section" bg="white.100" overflow="hidden">
          <Container
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            mt={["40px", null, null, "80px", null]}
            mb={["20px", "40px", null, "50px", null]}
          >
            {content.whoworkwithey &&
              content.whoworkwithey.map((message, index) => (
                <Bubble
                  left={!(index % 2)}
                  light={true}
                  wide={true}
                  description={message.description}
                  name={message.name}
                  position={message.position}
                  department={message.department}
                  image={message.image}
                  button={{
                    href: message.button.link,
                    label: message.button.text,
                  }}
                  heading={message.heading}
                />
              ))}
          </Container>
        </Wrapper>
      )}

      <CareerGrowth careers={careerGrowthData} />

      <BranchMap branches={branchMapData} />
    </Layout>
  );
};

export const query = graphql`
  query AboutPageQuery {
    mdx(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
        title
        metaTitle
        metaDescription
        headerImage {
          image {
            childImageSharp {
              gatsbyImageData(width: 1920, quality: 90)
            }
          }
          imageAlt
        }
        whoworkwithey {
          image {
            childImageSharp {
              gatsbyImageData(width: 88, height: 88, quality: 90, layout: FIXED)
            }
          }
          button {
            link
            text
          }
          heading
          name
          position
          description
          department
        }
      }
    }
    avastLogo: file(relativePath: { eq: "references/avast.png" }) {
      id
      childImageSharp {
        gatsbyImageData(height: 51, quality: 90, layout: FIXED)
      }
    }
    cashbotLogo: file(relativePath: { eq: "references/cashbot.png" }) {
      id
      childImageSharp {
        gatsbyImageData(height: 51, quality: 90, layout: FIXED)
      }
    }
    topicLinksImage1: file(relativePath: { eq: "about-topic/audit.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 90)
      }
    }
    topicLinksImage2: file(
      relativePath: { eq: "about-topic/forenzni_sluzby.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 90)
      }
    }
    topicLinksImage3: file(relativePath: { eq: "about-topic/advisory.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 90)
      }
    }
    topicLinksImage4: file(
      relativePath: { eq: "about-topic/it_consulting.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 90)
      }
    }
    topicLinksImage5: file(relativePath: { eq: "about-topic/dane.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 90)
      }
    }
    topicLinksImage6: file(relativePath: { eq: "about-topic/transakce.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 90)
      }
    }
    topicLinksImage7: file(relativePath: { eq: "about-topic/pravo.png" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 90)
      }
    }
    careerImage1: file(relativePath: { eq: "career/1.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 1350, quality: 90, layout: FIXED)
      }
    }
    careerImage2: file(relativePath: { eq: "career/2.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 1266, quality: 90, layout: FIXED)
      }
    }
    careerImage3: file(relativePath: { eq: "career/3.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 1266, quality: 90, layout: FIXED)
      }
    }
    careerImage4: file(relativePath: { eq: "career/4.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 1350, quality: 90, layout: FIXED)
      }
    }
    careerImage5: file(relativePath: { eq: "career/5.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 1266, quality: 90, layout: FIXED)
      }
    }
    careerImage6: file(relativePath: { eq: "career/6.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 1266, quality: 90, layout: FIXED)
      }
    }
    brancheMapImage1: file(
      relativePath: { eq: "maps/EY_mapa-praha_na-web.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(width: 950, quality: 90)
      }
    }
    brancheMapImage2: file(
      relativePath: { eq: "maps/EY_mapa-brno_na-web.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(width: 950, quality: 90)
      }
    }
    brancheMapImage3: file(
      relativePath: { eq: "maps/EY_mapa-ostrava_na-web.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(width: 950, quality: 90)
      }
    }
  }
`;

export default AboutPage;
